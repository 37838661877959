.players {
    display: flex;
    justify-content: space-evenly;
    margin-top: $spacing;

    .player {
        min-width: 10rem;
        margin-left: $spacing;
        margin-right: $spacing;
        margin-bottom: $spacing;
        background-color: $box-background;
        padding: 0.5rem;
        border-radius: 0.25rem;

        .points {
            font-size: 0.7rem;
            text-align: center;
        }

        .name {
            text-align: center;
        }

        .answer {
            margin-top: 1rem;
            font-weight: bold;
            text-align: center;

            .correct {
                color: rgb(64, 192, 87);
            }
            .incorrect {
                color: rgb(250, 82, 82);
            }
        }

        &.answered-correctly {
            background-color: rgb(235, 251, 238);
            color: rgb(64, 192, 87);
        }

        &.answered-incorrectly {
            background-color: rgb(255, 245, 245);
            color: rgb(250, 82, 82);
        }

        &.current-victim {
            background-color: rgba(231, 245, 255, 1);
            color: #228be6;
        }
    }
}

@media screen and (max-width: 600px) {
    .players {
        flex-direction: column;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-top: 0.5rem;

        .player {
            width: 100%;
            margin: 0 0 0.5rem 0;
        }
    }
}