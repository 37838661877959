.statusbar {
    font-size: 0.8rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    transition: all 0.1s;

    .room-id {
        background-color: rgba(0, 0, 0, 0.1);
        padding: 0.1rem 0.3rem 0.1rem 0.3rem;
        cursor: pointer;
    }
}

.statusbar.status-reconnecting {
    background-color: rgb(253, 126, 20);
}

.statusbar.status-connected {
    background-color: $box-background;
}

.statusbar.status-disconnected {
    color: rgb(255, 245, 245);
    background-color: rgb(250, 82, 82);
}

.statusbar.hidden {
    visibility: hidden;
    height: 0px;
    max-height: 0px;
    margin: 0px;
}

@media screen and (max-width: 600px) {
    .statusbar {
        margin-bottom: 0.5rem;
    }
}