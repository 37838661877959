.question-container {
    .question {
        background-color: $box-background;
        padding: 1rem;
        text-align: center;

        .hint {
            margin-top: $spacing;
        }

        .text {
            font-size: 2rem;
        }
    }

    .answer-text {
        margin-top: $spacing;
        background-color: $box-background;
        padding: 1rem;
    }

    .answers {
        margin-top: $spacing;
        display: flex;
        justify-content: space-evenly;

        >div {
            width: 10rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            background-color: $box-background;
            padding: 0.5rem;
            border-radius: 0.25rem;
            text-align: center;
            cursor: pointer;
        }
        
        div.selected {
            background-color: rgba(200, 200, 200, 1);
            font-weight: bold;
        }
    }

    .tags {
        text-align: right;
    }
}

@media screen and (max-width: 600px) {
    .question-container {
        .answers {
            margin-top: 0;
            flex-direction: column;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-top: 0.5rem;

            >div {
                width: 100%;
                margin: 0 0 0.5rem 0;
            }
        }
    }
}