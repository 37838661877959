body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$body-background: rgb(76, 96, 133); // 4c6085
$box-background: hsl(330, 12%, 97%); // f8f6f7
$badge-background: rgb(108, 117, 125);
$spacing: 0.5rem;

@import 'scss/reset.css';
@import 'scss/spacing';
@import 'scss/text';
@import 'scss/login';
@import 'scss/configurator';
@import 'scss/statusbar';
@import 'scss/layout';
@import 'scss/players';
@import 'scss/questions';
@import 'scss/controls';
@import 'scss/gameover';
