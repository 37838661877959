$spacingLevels: 5;

@for $i from 1 through $spacingLevels {
    .ml-#{$i} {
        margin-left:$spacing * $i;
    }
    .mr-#{$i} {
        margin-right:$spacing * $i;
    }
    .mb-#{$i} {
        margin-bottom:$spacing * $i;
    }
    .mt-#{$i} {
        margin-top:$spacing * $i;
    }
}