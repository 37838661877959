.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .login {
        background-color: $box-background;
        width: 20rem;
        padding: 0.5rem;
    }
    .login-header {
        h1 {
            font-size: 1.5rem;
        }
        h2 {
            font-style: italic;
            font-size: 0.8rem;
        }
        text-align: center;
        margin-bottom: 1rem;
    }

    input {
        width: 100%;
    }
    button {
        width: 100%;
        padding: $spacing*2;
    }
}