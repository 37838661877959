
.game-over {
    background-color: $box-background;
    margin: 0 0.5rem 0 0.5rem;
    padding: 0.5rem;

    h1 {
        font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
    }
    .player {
        margin-bottom: 0.5rem;
        .name {
            font-weight: bold;
        }
        .points {
            margin-left: 1rem;
        }
    }
}