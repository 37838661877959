body {
    background-color: $body-background;
    padding-bottom: $spacing * 2;
}

.badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    background-color: $badge-background;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.version-info {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 0.8rem;
    padding-left: 0.2rem;
    padding-bottom: 0.2rem;
    color: #ddd;
}