.room-config-wrapper {
    display: flex;
    justify-content: space-evenly;

    .room-config {
        background-color: $box-background;
        padding: 1rem;
        width: 20rem;

        >* {
            margin-bottom: $spacing;
        }
        >label {
            display: block;
            width: 100%;

            >input,
            select {
                display: block;
                width: 100%;
            }
        }

        button {
            margin-top: 1rem;
            display: block;
            width: 100%;
        }

        .tag-selector {
            display: grid;
            grid-template-columns: 1fr 1fr;
            label {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .room-config-wrapper {
        .room-config {
            width: 100%;
            margin: 0 0.5rem 0 0.5rem;
        }
    }
}